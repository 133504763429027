.card-container {
  perspective: 2500px;
  transform-style: preserve-3d;
  background-color: transparent;
  height: 100vh;
  width: 100vw;
  display: grid;
  justify-items: center;
  align-items: center;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  overflow-x: hidden;
}

.card {
  grid-row: 1/2;
  grid-column: 1/2;
  min-height: 30rem;
  width: 60rem;
  display: grid;
  background-color: transparent;

  border: solid #3b3934 2px;
  border-radius: 25px;
  padding: 2rem;
}

.text {
  font-family: athelas;
  font-size: 3em;
  z-index: 2;
}

.links {
  grid-row: 1/2;
  grid-column: 1/2;
  position: absolute;
  display: grid;

  width: 55rem;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(4, 1fr);
  text-align: center;
  font-size: 2em;
  align-items: center;
}
.svgLogo {
  width: 2em;
  height: 2em;
  align-items: center;
}
.svgLogoPath {
  fill: black;
  transition: 0.2s;
}
.svgLogo:hover .svgLogoPath {
  fill: rgb(92, 79, 72);
}
a {
  color: black;
  text-decoration: none;
  transition: 0.2s;
}
/* Each state */

a:visited {
  text-decoration: none;
  color: black;
}
a:hover {
  text-decoration: none;
  color: rgb(92, 79, 72);
}
a:focus {
  text-decoration: none;
  color: black;
}

.c2 {
  min-height: 30rem;
  width: 60rem;
  display: grid;
  justify-items: center;
  align-items: center;
  background-color: transparent;
  padding: 2rem;
  z-index: 0;
}

.c3 {
  min-height: 25rem;
  width: 12rem;
  z-index: 3;
  font-size: 2em;
  justify-items: center;
  align-items: center;
}
.albums-text {
  transform-origin: center;
  transform: rotateZ(90deg);
  font-size: 3rem;
  padding: 1.5rem;
  border-radius: 8px;
  background-color: #3b3934;
  color: #e6e2d8;
}
.albums-text:visited {
  color: #e6e2d8;
}
.albums-text:hover {
  text-decoration: none;
  color: rgb(92, 79, 72);
}
.c4 {
  min-height: 12rem;
  width: 42rem;
  z-index: 1;
  justify-items: end;
  align-items: center;
  font-size: 2em;
}
.contacts-text {
  font-size: 2rem;
  padding: 1.5rem;
  border-radius: 8px;
  background-color: #3b3934;
  color: #e6e2d8;
}
.contacts-text:visited {
  color: #e6e2d8 !important;
}
.contacts-text:hover {
  text-decoration: none;
  color: rgb(92, 79, 72);
}
.l2 {
  grid-row: 1/2;
  grid-column: 1/2;
  max-height: 50rem;
  min-height: 0.8rem;
  width: 50rem;
  display: grid;
  background-color: transparent;
  border: solid #3b3934 2px;
  border-radius: 25px;
  padding: 3rem;
  z-index: 0;
}
