@media only screen and (max-width: 1690px) {
  .card {
    min-height: 20rem;
    width: 40rem;
    display: grid;
  }

  .text {
    font-family: athelas;
    font-size: 2.5em;
    z-index: 2;
  }

  .links {
    grid-row: 1/2;
    grid-column: 1/2;
    position: absolute;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(6, 1fr);
    text-align: center;
    font-size: 1.5em;
    align-items: center;
  }
  .svgLogo {
    width: 2.5rem;
    height: 2.5rem;
    align-items: center;
  }
  .insta {
    grid-column: 2/3;
  }
  .twitter {
    grid-column: 3/4;
  }
  .c3 {
    min-height: 18rem;
    width: 9rem;
    z-index: 3;
    justify-items: center;
    align-items: center;
  }
  .albums-text {
    font-size: 2.5rem;
    padding: 1.5rem;
    border-radius: 6px;
  }

  .c4 {
    min-height: 6rem;
    width: 25rem;
    z-index: 1;
    justify-items: end;
    align-items: center;
    margin-right: 2rem;
  }

  .contacts-text {
    font-size: 2rem;
    padding: 1.5rem;
    border-radius: 6px;
  }

  .l2 {
    height: 6px;
    width: 34rem;
    border-radius: 25px;
    padding: 3rem;
    z-index: 0;
  }
}

/* ================================================== */

@media only screen and (max-width: 1200px) {
  .card {
    min-height: 20rem;
    width: 28rem;
  }

  .text {
    font-size: 2em;
  }

  .links {
    grid-template-columns: repeat(6, 1fr);
  }
  .svgLogo {
    width: 2.2rem;
    height: 2.2rem;
    align-items: center;
  }
  .insta {
    grid-column: 2/3;
  }
  .twitter {
    grid-column: 3/4;
  }
  .c3 {
    min-height: 13rem;
    width: 6rem;
    z-index: 3;
  }
  .albums-text {
    font-size: 1.7rem;
    padding: 1rem;
    border-radius: 6px;
  }

  .c4 {
    min-height: 4rem;
    width: 20rem;
    z-index: 1;
    justify-items: end;
    align-items: center;
    margin-right: 1.2rem;
  }

  .contacts-text {
    font-size: 1.5rem;
    padding: 1.5rem;
    border-radius: 6px;
  }

  .l2 {
    height: 3px;
    width: 28rem;
    border-radius: 25px;
    padding: 3rem;
    z-index: 0;
  }
}
