@media only screen and (max-width: 1400px) {
  .monolith-container {
    display: grid;
    height: 100vh;
    width: 100vw;
    justify-items: center;
    align-items: center;
  }

  .monolith-card {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 5rem;
    padding-top: 5vh;
  }
  .monolith-jacket {
    overflow: hidden;
    position: relative;
    width: 35vw;
    height: 35vw;
    /* border: 2vw #3b3934 solid; */
    justify-self: center;
    align-self: center;
  }
  .monolith-image {
    width: 35vw;
    height: 35vw;
    z-index: 1;
    position: absolute;
  }
  .monolith-songs {
    grid-column-start: 1;
    align-self: center;
    list-style: decimal;
  }
  ul {
    list-style: decimal;
  }
  .monolith-background {
    z-index: 0;
    text-align: center;
    position: absolute;
  }
  .monolith-info {
    grid-column-start: 2;
    grid-row: 1/2;
    text-align: center;
    overflow: hidden;
  }
  .monolith-header {
    font-size: 3rem;
    display: inline-block;
    color: #000000;
    border-bottom: solid 1px #2b2928;
  }
  .monolith-span {
    color: #000000;
    font-size: 4rem;
  }
  .monolith-text {
    padding-top: 3rem;
    padding-bottom: 1rem;
    font-size: 1.2rem;
    font-family: "Courier New", Courier, monospace;
  }
  .monolith-ego {
    width: 380px;
    height: 240px;
  }

  .monolith-link {
    font-weight: bold;
    padding-top: 2rem;
    font-family: "Courier New", Courier, monospace;
    color: #383635;
    font-size: 2rem;
    transition: 0.2s;
    width: auto;
    transform-origin: center;
  }

  .monolith-link:hover {
    color: #504a46;
    transform: rotateZ(20deg);
  }
}

/* ================================================== */
@media only screen and (max-width: 1200px) {
  .monolith-container {
    display: grid;
    height: 100vh;
    width: 100vw;
    justify-items: center;
    align-items: center;
    overflow-y: scroll;
  }

  .monolith-card {
    /* padding-top: 50vh; */
    margin-bottom: 15vh;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
  .monolith-jacket {
    grid-row-start: 1;
    grid-column-start: 1;
    overflow: hidden;
    position: relative;
    width: 35vw;
    height: 35vw;
    min-width: 400px;
    min-height: 400px;
    justify-self: center;
    align-self: center;
  }
  .monolith-image {
    width: 35vw;
    height: 35vw;
    min-width: 400px;
    min-height: 400px;
    z-index: 1;
    position: absolute;
  }

  .monolith-background {
    z-index: 0;
    text-align: center;
    position: absolute;
  }
  .monolith-info {
    grid-column-start: 1;
    grid-row: 2/3;
    text-align: center;
    overflow: hidden;
  }
  .monolith-header {
    font-size: 2.5rem;
    display: inline-block;
    color: #000000;
    border-bottom: solid 1px #2b2928;
  }
  .monolith-span {
    color: #000000;
    font-size: 4rem;
  }
  .monolith-text {
    padding-top: 3rem;
    padding-bottom: 1rem;
    font-size: 1.2rem;
    font-family: "Courier New", Courier, monospace;
  }
  .monolith-ego {
    width: 380px;
    height: 240px;
  }
}
/* ================================================== */
@media only screen and (max-width: 700px) {
  .monolith-card {
    /* padding-top: 500px; */
    margin-bottom: 15vh;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    row-gap: 1rem;
  }
  .monolith-jacket {
    grid-row: 2/3;
    grid-column-start: 1;
    overflow: hidden;
    position: relative;
    width: 80vw;
    height: 80vw;
    min-width: 250px;
    min-height: 250px;

    justify-self: center;
    align-self: center;
  }
  .monolith-image {
    width: 80vw;
    height: 80vw;
    min-width: 250px;
    min-height: 250px;
    z-index: 1;
    position: absolute;
  }

  .monolith-songs {
    grid-column-start: 1;
    grid-row: 3/4;
    align-self: center;
  }

  .monolith-info {
    grid-row: 1/2;
  }
}
